@import "./globalStyles";














* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
a {
    text-decoration: none;
}
ul {
    list-style: none;
}
body {
    height: 100%;
    width: 1449px*$vw;
    font-family: 'Atyp Display';
    overflow-x: hidden;
    background-color: #1C1C1C;
}
main {
    padding-top: 197px*$vw;
    overflow: hidden;
}

.newBtn {
    outline: none;
    background-color: transparent;
    padding: 10px 20px;
    border: 1px solid #03DFED;
    border-radius: 10px;
    transition: 0.3s;
    
    font-family: 'Neue Machina';
    font-style: normal;
    font-weight: 400;
    font-size: 17px*$vw;
    line-height: 16px*$vw;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    
    &.JR_btn {
        padding: 20px 50px;
    }
    &:hover {
        background-color: #03DFED;
    }
}


@media screen and (max-width: 500px) {
    .newBtn {
        font-size: 17px*$vw*3;
        line-height: 16px*$vw*3;
        
        &.JR_btn {
            padding: 10px 30px;
        }
    }
}