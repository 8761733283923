@import "../../../globalStyles";



.BlockchainTechs {
    width: 100%;
    margin-top: 238px*$vw;
    padding: 0 140px*$vw;

    @include flex(flex, column, nowrap, flex-start, center, unset);
}
.BlockchainTechs_bg {
    width: 900px*$vw;
    height: 800px*$vw;
    position: absolute;
    top: -250px*$vw;
    left: -155px*$vw;
    z-index: -1;

    @include flex(flex, row, nowrap, center, center, unset);
    
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.Blockchain_techs {
    width: 100%;
    position: relative;

    @include flex(flex, row, nowrap, space-between, flex-start, unset);

    .new {
        width: 193px*$vw;
        font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 400;
        font-size: 21px*$vw;
        line-height: 109.5%;
        color: #FFFFFF;
        padding-left: 14px*$vw;
        border-left: 0.5px*$vw solid #FFFFFF;
    }
    .Blockchain_right {
        width: 620px*$vw;

        h2 {
            width: 688px*$vw;
            font-family: 'Atyp Display';
            font-style: normal;
            font-weight: 700;
            font-size: 50px*$vw;
            line-height: 57px*$vw;
            color: #FFFFFF;
            margin-bottom: 30px*$vw;
        }
        p{
            font-family: 'Atyp Display';
            width: 530px;
            font-style: normal;
            font-weight: 400;
            font-size: 21px;
            line-height: 44px;

            color: #FFFFFF;

        }
        ul li {
            margin-bottom: 30px*$vw;

            @include flex(flex, row, nowrap, flex-start, flex-start, unset);

            .icon {
                width: 15px*$vw;
                height: 25px*$vw;
                margin-right: 15px*$vw;
                margin-top: 7px*$vw;

                @include flex(flex, row, nowrap, center, center, unset);

                img {
                    width: 180%;
                    height: 180%;
                    object-fit: contain;
                }
            }
            .text {
                width: 560px*$vw;
                font-family: 'Atyp Display';
                font-style: normal;
                font-weight: 400;
                font-size: 21px*$vw;
                line-height: 24px*$vw;
                color: #FFFFFF;
            }
        }
    }
}

.Blockchain_items {
    width: 100%;
    margin-top: 300px*$vw;

    @include flex(flex, row, wrap, flex-start, flex-start, unset);

    gap: 56px*$vw;
    .NoMater_item_bg{
        width: 500px;
        height: 600px;
        left: 0px;

    }
    .Blockchain_item {
        
        width: 352px*$vw;
        height: 420px*$vw;
        position: relative;
        padding: 55px 0px 48px 41px;
        /* background: rgba(37, 116, 121, 0.06);
        box-shadow: 0px*$vw 0px*$vw 14px*$vw rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(15px*$vw);
        border-radius: 50px*$vw;

        border: double 1px*$vw transparent;
        background-image: linear-gradient(#281C1D, #281C1D), linear-gradient(to bottom, #03DFED, rgba(255, 255, 255, 0.15), #000);
        background-origin: border-box;
        background-clip: content-box, border-box; */
        &:nth-child(3) .img {
            left: 140px;
            
        }
        @include flex(flex, column, nowrap, flex-start, flex-start, unset);

        .img {
            width: 366px*$vw;
            height: 206px*$vw;
            position: absolute;
            right: -150px*$vw;
            top: -80px*$vw;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        h4 {
            height: 100%;
            width: 171px*$vw;
            font-family: 'Atyp Display';
            font-style: normal;
            font-weight: 700;
            font-size: 28px*$vw;
            line-height: 34px*$vw;
            color: #03DFED;
            ;
            margin: 50px*$vw 0 0 25px*$vw;
        }
        .text {
            height: 100%;
            font-family: 'Atyp Display';
            font-style: normal;
            font-weight: 400;
            font-size: 21px*$vw;
            line-height: 24px*$vw;
            color: #FFFFFF;
            margin: 25px*$vw;
        }
    }
}











@media screen and (max-width: 500px) {

    .BlockchainTechs {
        
        width: 100%;
        margin-top: -120px*$TELvw;
        padding: 150px*$TELvw 10px*$TELvw 15px*$TELvw 10px*$TELvw;
        overflow: hidden;
    }
    .BlockchainTechs_bg {
        width: 1400px*$TELvw*0.42;
        height: 1800px*$TELvw*0.42;
        position: absolute;
        top: -750px*$TELvw*0.42;
        left: -100px*$TELvw*0.42;
    }
    .NoMater_item_bg{
        height: 100%;
        width: 100%;
    }
    .Blockchain_techs {
        width: 100%;
        position: relative;

        .new {
            width: 193px*$TELvw*0.5;
            font-size: 21px*$TELvw*0.5;
            padding-left: 14px*$TELvw*0.5;
            border-left: 0.5px*$TELvw*0.5 solid #FFFFFF;
            margin-top: 200px*$TELvw;
        }
        .Blockchain_right {
            width: 70%;
            margin-top: 200px*$TELvw;

            h2 {
                width: 508px*$TELvw*0.5;
                font-size: 40px*$TELvw*0.5;
                line-height: 57px*$TELvw*0.5;
                margin-bottom: 30px*$TELvw*0.5;
            }
            p{
                width: 508px*$TELvw*0.5;
                font-size: 20px*$TELvw*0.5;
                line-height: 27px*$TELvw*0.5;
                margin-bottom: 30px*$TELvw*0.5;
            }
            ul li {
                margin-bottom: 30px*$TELvw*0.7;

                .icon {
                    width: 18px*$TELvw*1.5;
                    height: 31.23px*$TELvw*1.5;
                    margin-right: 15px;
                    margin-top: -5px*$TELvw;
                }
                .text {
                    width: 560px*$TELvw*0.7;
                    font-size: 21px*$TELvw*0.7;
                    line-height: 25px*$TELvw*0.7;
                }
            }
        }
    }

    .Blockchain_items {
        height: 100%;
        width: 100%;
        margin-top: 150px*$vw;

        @include flex(flex, column, nowrap, flex-start, center, unset);
        margin-left: -70px;
        gap: 100px*$TELvw;
        margin-bottom: 150px;
        
        .Blockchain_item {
            height: 100%;
            margin-left: 50px;
            width: 90%;
        
            /* box-shadow: 0px*$TELvw*0.8 0px*$TELvw*0.8 14px*$TELvw*0.8 rgba(0, 0, 0, 0.7);
            backdrop-filter: blur(15px*$TELvw*0.8);
            border-radius: 50px*$TELvw*0.8;
            padding-left: 50px;
            border: double 1px*$TELvw*0.8 transparent; */

            /* padding: 40px; */

            .NoMater_item_bg{
                width: 100%;
                
            }

            .img {
                height: 100%;
                width: 352px*$TELvw*0.8;
                height: 252px*$TELvw*0.8;
                position: absolute;
                right: -100px*$TELvw*0.8;
                top: -100px*$TELvw*0.8;
            
            }
            h4 {
                height: 100%;
      
                font-size: 28px*$TELvw*0.8;
                line-height: 34px*$TELvw*0.8;
                margin: 50px*$TELvw*0.8 0 0 25px*$TELvw*0.8;
            }
            .text {
                height: 100%;
                font-size: 21px*$TELvw*0.8;
                line-height: 28px*$TELvw*0.8;
                margin: 25px*$TELvw*0.8;
                margin-bottom: 40px*$TELvw;
            }
        }
    }
}

@media screen and (max-width: 460px){


    .Blockchain_items {
        height: 100%;
        width: 100%;
        margin-top: 150px*$vw;

        @include flex(flex, column, nowrap, flex-start, center, unset);
        margin-left: -70px;
        gap: 80px*$TELvw;
        margin-bottom: 150px;
        
        .Blockchain_item {
            height: 100%;
            margin-left: 50px;
            width: 90%;
        
            /* box-shadow: 0px*$TELvw*0.8 0px*$TELvw*0.8 14px*$TELvw*0.8 rgba(0, 0, 0, 0.7);
            backdrop-filter: blur(15px*$TELvw*0.8);
            border-radius: 50px*$TELvw*0.8;
            padding-left: 50px;
            border: double 1px*$TELvw*0.8 transparent; */

            /* padding: 40px; */
            &:nth-child(3) .img {
                left: 120px;
                
            }
            .NoMater_item_bg{
                width: 100%;
                
            }

            .img {
                height: 100%;
                width: 352px*$TELvw*0.8;
                height: 252px*$TELvw*0.8;
                position: absolute;
                right: -120px*$TELvw*0.8;
                top: -70px*$TELvw*0.8;
            
            }
            h4 {
                height: 100%;
                
                font-size: 28px*$TELvw*0.8;
                line-height: 34px*$TELvw*0.8;
                margin: 80px*$TELvw*0.8 0 0 25px*$TELvw*0.8;
            }
            .text {
                height: 100%;
                font-size: 21px*$TELvw*0.8;
                line-height: 28px*$TELvw*0.8;
                margin: 25px*$TELvw*0.8;
                margin-bottom: 40px*$TELvw;
            }
        }
    }

}

@media screen and (max-width: 420px){


    .Blockchain_items {
        height: 100%;
        width: 100%;
        margin-top: 150px*$vw;

        @include flex(flex, column, nowrap, flex-start, center, unset);
        margin-left: -70px;
        gap: 80px*$TELvw;
        margin-bottom: 150px;
        
        .Blockchain_item {
            height: 100%;
            margin-left: 50px;
            width: 90%;
        
            /* box-shadow: 0px*$TELvw*0.8 0px*$TELvw*0.8 14px*$TELvw*0.8 rgba(0, 0, 0, 0.7);
            backdrop-filter: blur(15px*$TELvw*0.8);
            border-radius: 50px*$TELvw*0.8;
            padding-left: 50px;
            border: double 1px*$TELvw*0.8 transparent; */

            /* padding: 40px; */
            &:nth-child(3) .img {
                left: 100px;
                
            }
            .NoMater_item_bg{
                width: 100%;
                
            }

            .img {
                height: 100%;
                width: 352px*$TELvw*0.8;
                height: 252px*$TELvw*0.8;
                position: absolute;
                right: -120px*$TELvw*0.8;
                top: -40px*$TELvw*0.8;
            
            }
            h4 {
                height: 100%;
                
                font-size: 28px*$TELvw*0.8;
                line-height: 34px*$TELvw*0.8;
                margin: 80px*$TELvw*0.8 0 0 25px*$TELvw*0.8;
            }
            .text {
                height: 100%;
                font-size: 21px*$TELvw*0.8;
                line-height: 28px*$TELvw*0.8;
                margin: 25px*$TELvw*0.8;
                margin-bottom: 40px*$TELvw;
            }
        }
    }

}


@media screen and (max-width: 380px){


    .Blockchain_items {
        height: 100%;
        width: 100%;
        margin-top: 150px*$vw;

        @include flex(flex, column, nowrap, flex-start, center, unset);
        margin-left: -70px;
        gap: 60px*$TELvw;
        margin-bottom: 150px;
        
        .Blockchain_item {
            height: 100%;
            margin-left: 50px;
            width: 90%;
        
            /* box-shadow: 0px*$TELvw*0.8 0px*$TELvw*0.8 14px*$TELvw*0.8 rgba(0, 0, 0, 0.7);
            backdrop-filter: blur(15px*$TELvw*0.8);
            border-radius: 50px*$TELvw*0.8;
            padding-left: 50px;
            border: double 1px*$TELvw*0.8 transparent; */

            /* padding: 40px; */
            &:nth-child(3) .img {
                left: 120px;
                
            }
            .NoMater_item_bg{
                width: 100%;
                
            }

            .img {
                height: 100%;
                width: 302px*$TELvw*0.8;
                height: 252px*$TELvw*0.8;
                position: absolute;
                right: -100px*$TELvw*0.8;
                top: 0px*$TELvw*0.8;
            
            }
            h4 {
                height: 100%;
                
                font-size: 28px*$TELvw*0.8;
                line-height: 34px*$TELvw*0.8;
                margin: 140px*$TELvw*0.8 0 0 25px*$TELvw*0.8;
            }
            .text {
                height: 100%;
                font-size: 21px*$TELvw*0.8;
                line-height: 28px*$TELvw*0.8;
                margin: 25px*$TELvw*0.8;
                margin-bottom: 40px*$TELvw;
            }
        }
    }

}


@media screen and (max-width: 360px){


    .Blockchain_items {
        height: 100%;
        width: 100%;
        margin-top: 150px*$vw;

        @include flex(flex, column, nowrap, flex-start, center, unset);
        margin-left: -70px;
        gap: 1px*$TELvw;
        margin-bottom: 150px;
        
        .Blockchain_item {
            height: 100%;
            margin-left: 80px;
            width: 90%;
        
            /* box-shadow: 0px*$TELvw*0.8 0px*$TELvw*0.8 14px*$TELvw*0.8 rgba(0, 0, 0, 0.7);
            backdrop-filter: blur(15px*$TELvw*0.8);
            border-radius: 50px*$TELvw*0.8;
            padding-left: 50px;
            border: double 1px*$TELvw*0.8 transparent; */

            /* padding: 40px; */

            .NoMater_item_bg{
                width: 100%;
                
            }

            .img {
                height: 100%;
                width: 302px*$TELvw*0.8;
                height: 252px*$TELvw*0.8;
                position: absolute;
                right: -100px*$TELvw*0.8;
                top: 20px*$TELvw*0.8;
            
            }
            h4 {
                height: 100%;
                
                font-size: 28px*$TELvw*0.8;
                line-height: 34px*$TELvw*0.8;
                margin: 160px*$TELvw*0.8 0 0 25px*$TELvw*0.8;
            }
            .text {
                height: 100%;
                font-size: 21px*$TELvw*0.8;
                line-height: 28px*$TELvw*0.8;
                margin: 25px*$TELvw*0.8;
                margin-bottom: 40px*$TELvw;
            }
        }
    }

}


@media screen and (max-width: 320px){


    .Blockchain_items {
        height: 100%;
        width: 100%;
        margin-top: 150px*$vw;

        @include flex(flex, column, nowrap, flex-start, center, unset);
        margin-left: -70px;
        gap: 60px*$TELvw;
        margin-bottom: 150px;
        
        .Blockchain_item {
            height: 100%;
            margin-left: 50px;
            padding-top: 20px;
            padding-bottom: 20px;
            width: 90%;
        
            /* box-shadow: 0px*$TELvw*0.8 0px*$TELvw*0.8 14px*$TELvw*0.8 rgba(0, 0, 0, 0.7);
            backdrop-filter: blur(15px*$TELvw*0.8);
            border-radius: 50px*$TELvw*0.8;
            padding-left: 50px;
            border: double 1px*$TELvw*0.8 transparent; */

            /* padding: 40px; */
            &:nth-child(3) .img {
                left: 100px;
                
            }
            .NoMater_item_bg{
                width: 100%;
                
            }

            .img {
                height: 100%;
                width: 302px*$TELvw*0.8;
                height: 252px*$TELvw*0.8;
                position: absolute;
                right: -100px*$TELvw*0.8;
                top: 70px*$TELvw*0.8;
            
            }
            h4 {
                height: 100%;
                
                font-size: 28px*$TELvw*0.8;
                line-height: 34px*$TELvw*0.8;
                margin: 200px*$TELvw*0.8 0 0 25px*$TELvw*0.8;
            }
            .text {
                height: 100%;
                width: 75%;
                font-size: 21px*$TELvw*0.8;
                line-height: 28px*$TELvw*0.8;
                margin: 25px*$TELvw*0.8;
                margin-bottom: 40px*$TELvw;
            }
        }
    }

}
