@import "../../globalStyles";









.Footer{
    height: 90px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #000000;
    .footer_logo{
        margin-left: 127px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
       
        
        p{
            font-family: 'Atyp Display';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.325em;
            text-transform: uppercase;

            color: #FFFFFF;
        }
        span{
            
            font-family: 'Atyp Display';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.5);

        }
    }
    .Gol{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    .Follow{
        display: flex;
        gap: 22px;
       
        .FNavTitle{
            font-family: 'Atyp Display';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            color: #FFFFFF;
        }
        .socials{
            display: flex;
            flex-direction: row;
            
            
            gap: 42px;
        }
    }
}
    .Contact{
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        margin-right: 150px;
        .FNavTitle{
            font-family: 'Atyp Display';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            color: #FFFFFF;
        }
        .email{
            font-family: 'Atyp Display';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #FFFFFF;

        }
    }
}

@media screen and (max-width: 500px){   

    .Footer{
        position: relative;
        height: 100%;
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        background-color: #000000;
        justify-content: center;
        align-items: center;
        padding: 15px 0;
        .footer_logo{
            margin-left: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
           
            width: 100%;
            height: 100%;
           
            
            p{
                font-family: 'Atyp Display';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 15px;
                letter-spacing: 0.325em;
                text-transform: uppercase;
    
                color: #FFFFFF;
            }
            span{
                
                font-family: 'Atyp Display';
                font-style: normal;
                font-weight: 400;
                font-size: 8px;
                line-height: -20px;
                color: rgba(255, 255, 255, 0.5);
    
            }
        }
        .Gol{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 15px;
            
        .Follow{
            display: flex;
            
           
            .FNavTitle{
                font-family: 'Atyp Display';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 13px;
                color: #FFFFFF;
                white-space: nowrap;
                margin-top: 5px;
            }
            .socials{
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 100%;
                gap: 20px;
            }
            .footer_social{
                width: 20px;
                height: 20px;
            }
        }
    }
        .Contact{
            display: flex;
            flex-direction: column;
            gap: 0px;
            justify-content: center;
            align-items: center;
            margin-top: 15px;
            margin-left: 170px;
            width: 100%;
            
            .FNavTitle{
                font-family: 'Atyp Display';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 23px;
                color: #FFFFFF;
            }
            .email{
                font-family: 'Atyp Display';
                font-style: normal;
                font-weight: 400;
                font-size: 8px;
                line-height: 5px;
                color: #FFFFFF;
    
            }
        }
    }
        
}