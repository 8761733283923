@import "../../../globalStyles";



.TopBanner {
    width: 100%;
    padding: 0 133px*$vw;
    position: relative;
    margin-bottom: 237px*$vw;

    @include flex(flex, column, nowrap, flex-start, center, unset);

    .title {
        width: 100%;
        font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 400;
        font-size: 62.9px*$vw*0.975;
        line-height: 98px*$vw*0.975;
        
        text-transform: uppercase;
        color: #FFFFFF;
        margin: 0;
        padding-bottom: 200px*$vw;

        position: relative;

        span {
            &:first-child {
                width: 100%;
                font-size: 63.7px*$vw*0.975;
                letter-spacing: 0.2em;
            }
        }

        .left,
        .right {
            display: block;
            position: absolute;
            font-weight: 700 !important;
            font-size: 60px*$vw !important;
            line-height: 157px*$vw !important;
        }
        .left {
            left: 0;
        }
        .right {
            right: 0;
        }
    }
}
.topBanner_bicas {
    display: none;
}
.TopBanner_btns {
    width: 100%;

    @include flex(flex, row, nowrap, space-between, flex-end, unset);

    &>div {
        @include flex(flex, column, nowrap, flex-start, flex-start, unset);

        p {
            margin-bottom: 30px*$vw;
        }
    }
    .left {
        width: 357px*$vw;
        position: relative;
        z-index: 2;

        p {
            font-family: 'Atyp Display';
            font-style: normal;
            font-weight: 400;
            font-size: 21px*$vw;
            line-height: 30px*$vw;
            color: #FFFFFF;
            width: 280px;
        }
    }
    .right {
        align-items: flex-end;
        position: relative;
        z-index: 2;

        p {
            width: 188px*$vw;
            font-family: 'Atyp Display';
            font-style: normal;
            font-weight: 400;
            font-size: 29px*$vw;
            line-height: 31px*$vw;
            text-align: right;
            text-transform: lowercase;
            color: #000000;
        }
    }
}

.TopBanner_scrollDown {
    font-family: 'Atyp Display';
    font-style: normal;
    font-weight: 400;
    font-size: 21px*$vw;
    line-height: 109.5%;
    text-align: right;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    transform: rotate(-90deg);
    position: absolute;
    left: 0;
    bottom: -150px*$vw;
}

.TopBanner_gif {
    width: 745px*$vw*1.8;
    height: 706px*$vw*1.8;
    position: absolute;
    right: 100px*$vw;
    top: -330px*$vw;
    z-index: -1;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}














@media screen and (max-width: 500px) {

    .TopBanner {
        width: 100%;
        padding: 0 10px*$TELvw;
        position: relative;
        margin-top: 200px*$TELvw;
        margin-bottom: 42px*$TELvw;

        .topBanner_bicas {
            display: block;
            font-family: 'Atyp Display';
            font-style: normal;
            font-weight: 400;
            font-size: 9px*$TELvw;
            line-height: 14px*$TELvw;
            text-align: center;
            letter-spacing: 0.8em;
            text-transform: uppercase;
            color: #FFFFFF;
            position: relative;
            top: -170px*$TELvw;
        }

        .title {
            width: 248px*$TELvw;
            font-size: 22px*$TELvw;
            line-height: 32px*$TELvw;
            padding-bottom: 58px*$TELvw;
            align-self: flex-start;
            

            span {
                &:first-child {
                    width: 110%;
                    font-size: 100.7px*$vw*0.975;
                }
            }

            .left,
            .right {
                
                font-size: 15px*$TELvw !important;
                line-height: 20px*$TELvw !important;
            }
            .right {
                margin-top: 20px;
                left: 0;
                
            }
        }
    }
    .TopBanner_btns {
        @include flex(flex, column, nowrap, flex-start, flex-start, unset);

        position: relative;

        &>div {
            @include flex(flex, column, nowrap, flex-start, flex-start, unset);

            p {
                margin-bottom: 30px*$TELvw*0.5;
            }
        }
        .left {
            width: 260px*$TELvw;

            p {
                font-size: 14px*$TELvw;
                line-height: 17px*$TELvw;
            }

            .Btn1 {
                padding: 16px*$TELvw 37px*$TELvw;
            }
        }
        .right {
            width: 40%;
            position: absolute;
            right: 0;
            bottom: -60px*$TELvw;

            @include flex(flex, column-reverse, nowrap, flex-start, flex-start, unset);

            p {
                width: 100px*$TELvw*1.2;
                font-size: 9px*$TELvw*1.1;
                line-height: 11px*$TELvw*1.1;
                letter-spacing: 0.44em;
                text-align: left;
                color: #fff;
                margin-top: 20px*$TELvw;
            }
            .Btn1 {
                padding: 16px*$TELvw 37px*$TELvw;
            }
        }
    }

    .TopBanner_scrollDown {
        font-size: 8px*$TELvw;
        line-height: 8px*$TELvw;
        bottom: -42px*$TELvw;
        left: -20px*$TELvw;
    }

    .TopBanner_gif {
        width: 649.45px*$TELvw;
        height: 691px*$TELvw;
        position: absolute;
        top: -320px*$TELvw;
        left: -80px*$TELvw;
        z-index: -1;
    }
}