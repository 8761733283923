@import "../../../globalStyles";



.JoinRevolution {
    /* z-index: -3; */
    
    width: 100%;
    height: 700px*$vw;
    margin-top: 130px;
    justify-content: center;
    align-items: center;
    margin-bottom: 150px;
    
    .JoinRevolution_bg {
        width: 100%;
        top: 0;
        z-index: -2;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        
        
        img {

            object-fit: cover;
        }
    }
}
.JoinRevolutin_text{
    display: flex;
    align-items: center;
    justify-content: center;
    

    h4{
        font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 700;
        font-size: 200px;
        line-height: 109.5%;
        /* identical to box height, or 219px */
        color: #03DFED;
    }
    p{
        font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 600;
        margin-left: 30px;
        font-size: 80px;
        margin-top: 1%;
        text-align: center;
        line-height: 109.5%;
        /* identical to box height, or 110px */
        color: #FFFFFF;
    }

}
.JoinRevolution_btn {
    position: absolute;
    right: 347px*$vw;
    bottom: 80px*$vw;
}


@media screen and (max-width: 500px) {
    .JoinRevolution {
        /* z-index: -3; */
        
        width: 100%;
        height: 100%;
        margin-top: -80px;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        
        
        .JoinRevolution_bg {
            width: 100%;
            margin-top: 30px;
            z-index: -2;
            display: flex;
            align-items: center;
            justify-content: center;
            
    
            img {
                width: 100%;
                object-fit: cover;
            }
        }
    }
    .JoinRevolutin_text{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        
        
    
        h4{
            font-family: 'Atyp Display';
            font-style: normal;
            font-weight: 700;
            font-size: 60px;
            line-height: 109.5%;
            /* identical to box height, or 219px */
            color: #03DFED;
        }
        p{
            font-family: 'Atyp Display';
            font-style: normal;
            font-weight: 600;
            margin-left: 30px;
            font-size: 25px;
            margin-top: 1%;
            text-align: center;
            line-height: 109.5%;
            /* identical to box height, or 110px */
            color: #FFFFFF;
        }
    
    }
    .JoinRevolution_btn {
        position: absolute;
        right: 237px*$vw;
        bottom: 80px*$vw;
    }
}
