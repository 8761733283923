@import "../../globalStyles";



.main {
    position: relative;
    padding-top: 200px*$vw;
}
.main_circleBlur {
    width: 940px*$vw;
    height: 943px*$vw;
    border-radius: 50%;
    background: #03DFED;
    filter: blur(250px*$vw);

    position: absolute;
    z-index: -2;
}
.circleBlur1 {
    top: -500px*$vw;
    left: -500px*$vw;
}
.circleBlur2 {
    top: 500px*$vw;
    right: -500px*$vw;
}
.circleBlur3 {
    top: 2500px*$vw;
    right: -600px*$vw;
}
.circleBlur4 {
    display: none;
}


.main_circleBlurRoadmap {
    width: 502px*$vw;
    height: 502px*$vw;
    border-radius: 50%;
    background: #03DFED;
    filter: blur(125px*$vw);

    position: absolute;
    z-index: -2;
}
.circleBlurRoadmap4 {
    bottom: 1400px*$vw;
    left: -300px*$vw;
}
.circleBlurRoadmap5 {
    bottom: 1300px*$vw;
    right: -200px*$vw;
    z-index: -2;
    
}
.circleBlurRoadmap6 {
    width: 550px*$vw;
    height: 550px*$vw;
    bottom: 3000px*$vw;
    left: -350px*$vw;
}
.circleBlurRoadmap7{
    width: 550px*$vw;
    height: 550px*$vw;
    bottom: 900px*$vw;
    right: -300px*$vw;
}
.circleBlurRoadmap8{
    width: 550px*$vw;
    height: 550px*$vw;
    bottom: 100px*$vw;
    left: -300px*$vw;
}

.circleBlurRoadmap9{
    width: 550px*$vw;
    height: 750px*$vw;
    bottom: 300px*$vw;
    right: -300px*$vw;
}





@media screen and (max-width: 500px) {

    .main {
        position: relative;
    }
    .main_circleBlur {
        width: 296px*$TELvw;
        height: 426px*$TELvw;
        background: #03DFED;
        filter: blur(75px*$TELvw);
        transform: rotate(32.41deg);

        position: absolute;
        z-index: -2;
    }
    .circleBlur1 {
        top: -200px*$TELvw;
        left: -200px*$TELvw;
    }
    .circleBlur2 {
        top: 200px*$TELvw;
        right: -200px*$TELvw;
    }
    .circleBlur3 {
        top: 2300px*$TELvw;
        left: -200px*$TELvw;
        transform: rotate(21.01deg);
    }
    .circleBlur4 {
        width: 235px*$TELvw;
        height: 235px*$TELvw;
        filter: blur(50px*$TELvw);
        top: 2700px*$TELvw;
        left: -100px*$TELvw;
        transform: rotate(21.01deg);
    }


    .main_circleBlurRoadmap {
        display: none;
    }
}