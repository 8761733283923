@import "../../../globalStyles";



.BicasGif2 {
    margin-bottom: 250px;
    width: 100%;
    padding: 0 133px*$vw;
    position: relative;
    

    @include flex(flex, column, nowrap, flex-start, center, unset);

   
}
.BicasGif2_text {
    width: 100%;    
    margin-top: 150px;
    

    @include flex(flex, row, nowrap, space-between, center, unset);

    span {
        width: 495px*$vw;
        height: fit-content;
        display: block;
        font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 400;
        font-size: 110px*$vw;
        line-height: 157px*$vw;
        color: #FFFFFF;
        margin-bottom: 42px*$vw;
        position: relative;

        &::after {
            content: '';
            width: 46px*$vw;
            height: 2px*$vw;
            background-color: #fff;
            position: absolute;
            top: 65%;
            right: -100px;
        }
    }
    p {
        margin-left: 140px;
        width: 628px*$vw;
        font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 400;
        font-size: 24px*$vw;
        line-height: 44px*$vw;
        color: #FFFFFF;
        
    }
}
.BicasGif2_img_wrapper {
    width: 1393px*$vw;
    height: 929px*$vw;
    margin-top: -1px*$vw;
    z-index: -1;
    position: relative;
    margin-top: -370px;
    margin-left: 20%;

    .BicasGif2_img {
        top: -50px;
        width: 1500px;
        height: 1500px;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    
    .BicasGif2_important {

        width: 228px*$vw;
        position: absolute;
        left: 153px*$vw;
        top: 60%;

        @include flex(flex, column, nowrap, flex-start, flex-start, unset);
    }
    .BicasGif2_important_icon {

        width: 21px*$vw;
        height: 21px*$vw;
        background: #03DFED 80%;
        border: 1px*$vw solid  #03DFED 80%;
        backdrop-filter: blur(2px*$vw);
        border-radius: 50%;

        font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 400;
        font-size: 16px*$vw;
        line-height: 23px*$vw;
        text-align: center;
        text-transform: lowercase;
        color: #FFFFFF;
        margin-bottom: 4px*$vw;

        @include flex(flex, row, nowrap, center, center, unset);
    }
    p {
        font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 400;
        font-size: 15px*$vw;
        line-height: 126.1%;
        text-transform: lowercase;
        color: #FFFFFF;
    }

    

    
}

.BicasGif2_text_button{
            
            
            span {
                font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.17em;
        text-transform: uppercase;

        text-shadow:0px 1px 0 #06F0FF,0px -1px 0 #06F0FF,1px 0px 0 #06F0FF,-1px 0px 0 #06F0FF,1px 1px 0 #06F0FF,1px -1px 0 #06F0FF,-1px 1px 0 #06F0FF,-1px -1px 0 #06F0FF;
                        -webkit-text-shadow:0px 1px 0 #06F0FF,0px -1px 0 #06F0FF,1px 0px 0 #06F0FF,-1px 0px 0 #06F0FF,1px 1px 0 #06F0FF,1px -1px 0 #06F0FF,-1px 1px 0 #06F0FF,-1px -1px 0 #06F0FF;
                        -moz-text-shadow:0px 1px 0 #06F0FF,0px -1px 0 #06F0FF,1px 0px 0 #06F0FF,-1px 0px 0 #06F0FF,1px 1px 0 #06F0FF,1px -1px 0 #06F0FF,-1px 1px 0 #06F0FF,-1px -1px 0 #06F0FF;
                        color: #151515;

/* Inside auto layout */
    }
    p{
                font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.17em;
        text-transform: uppercase;

        color: #FFFFFF;

    }

}
.BicasGif2_text_button_US{
    
    
    font-family: 'Atyp Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */

    text-align: left;
    letter-spacing: 0.17em;
    text-transform: uppercase;

    color: #B1B1B1;

}
.BicasGif2_text_button_all{
    margin-right: 20%;
   
    position: absolute;
    right: 0;
    bottom: 140px;
}













@media screen and (max-width: 500px) {

    .BicasGif2 {
        width: 100%;
        padding: 0 10px*$TELvw;
        padding-bottom: 70px*$TELvw;
        margin-top: 40px*$TELvw;
        margin-bottom: -150px*$TELvw;
        overflow: hidden;
    }
    .BicasGif2_text {
        width: 100%;
        margin-bottom: 100px*$TELvw;
        position: relative;
        @include flex(flex, column, nowrap, flex-start, center, unset);

        span {
            width: 90%;
            font-size: 62px*$TELvw;
            line-height: 74px*$TELvw;
            margin-bottom: 42px*$TELvw*0.3;
            text-align: left;

            &::after {
                display: none;
            }
        }
        p {
            position: absolute;
            width: 143px*$TELvw;
            font-size: 12px*$TELvw;
            line-height: 14px*$TELvw;
            text-align: start;
            align-self: flex-end;
            
            left: -110px;
            top: 200px;
        }
    }
    .BicasGif2_img_wrapper {
        width: 800px*$TELvw*0.6;
        height: 1129px*$TELvw*0.6;
        margin-top: -450px;
        position: relative;
        z-index: -1;

        @include flex(flex, row, nowrap, center, center, unset);

        .BicasGif2_img {
            width: 70%;
        }

        img {
            width: 210%;
            height: 210%;
            z-index: 100;
            object-fit: contain;
        }

        .BicasGif2_important {
            display: none;
        }
    }



        .BicasGif2_text_button{
                
                
            span {
                font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.17em;
        text-transform: uppercase;

        text-shadow:0px 1px 0 #06F0FF,0px -1px 0 #06F0FF,1px 0px 0 #06F0FF,-1px 0px 0 #06F0FF,1px 1px 0 #06F0FF,1px -1px 0 #06F0FF,-1px 1px 0 #06F0FF,-1px -1px 0 #06F0FF;
                        -webkit-text-shadow:0px 1px 0 #06F0FF,0px -1px 0 #06F0FF,1px 0px 0 #06F0FF,-1px 0px 0 #06F0FF,1px 1px 0 #06F0FF,1px -1px 0 #06F0FF,-1px 1px 0 #06F0FF,-1px -1px 0 #06F0FF;
                        -moz-text-shadow:0px 1px 0 #06F0FF,0px -1px 0 #06F0FF,1px 0px 0 #06F0FF,-1px 0px 0 #06F0FF,1px 1px 0 #06F0FF,1px -1px 0 #06F0FF,-1px 1px 0 #06F0FF,-1px -1px 0 #06F0FF;
                        color: #151515;

    /* Inside auto layout */
    }
    p{
                font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.17em;
        text-transform: uppercase;

        color: #FFFFFF;

    }

    }
    .BicasGif2_text_button_US{


        font-family: 'Atyp Display';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 20px;
    /* identical to box height */

    text-align: left;
    letter-spacing: 0.17em;
    text-transform: uppercase;

    color: #B1B1B1;

    }
    .BicasGif2_text_button_all{
    margin-right: 108px;
    position: absolute;
    top: 65%;
    right: 0;
   
    }

}







@media screen and (max-width: 420px) {

    .BicasGif2 {
        width: 100%;
        padding: 0 10px*$TELvw;
        padding-bottom: 70px*$TELvw;
        margin-top: 40px*$TELvw;
        margin-bottom: -150px*$TELvw;
        overflow: hidden;
    }
    .BicasGif2_text {
        width: 100%;
        margin-bottom: 100px*$TELvw;
        position: relative;
        @include flex(flex, column, nowrap, flex-start, center, unset);

        span {
            width: 90%;
            font-size: 62px*$TELvw;
            line-height: 74px*$TELvw;
            margin-bottom: 42px*$TELvw*0.3;
            text-align: left;

            &::after {
                display: none;
            }
        }
        p {
            position: absolute;
            width: 143px*$TELvw;
            font-size: 12px*$TELvw;
            line-height: 14px*$TELvw;
            text-align: start;
            align-self: flex-end;
            
            left: -110px;
            top: 200px;
        }
    }
    .BicasGif2_img_wrapper {
        width: 800px*$TELvw*0.6;
        height: 1129px*$TELvw*0.6;
        margin-top: -450px;
        position: relative;
        z-index: -1;

        @include flex(flex, row, nowrap, center, center, unset);

        .BicasGif2_img {
            width: 70%;
            margin-top: 150px;
        }

        img {
            width: 210%;
            height: 210%;
            z-index: 100;
            object-fit: contain;
        }

        .BicasGif2_important {
            display: none;
        }
    }









    .BicasGif2_text_button{
                
                
        span {
            font-family: 'Atyp Display';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.17em;
    text-transform: uppercase;

    text-shadow:0px 1px 0 #06F0FF,0px -1px 0 #06F0FF,1px 0px 0 #06F0FF,-1px 0px 0 #06F0FF,1px 1px 0 #06F0FF,1px -1px 0 #06F0FF,-1px 1px 0 #06F0FF,-1px -1px 0 #06F0FF;
                    -webkit-text-shadow:0px 1px 0 #06F0FF,0px -1px 0 #06F0FF,1px 0px 0 #06F0FF,-1px 0px 0 #06F0FF,1px 1px 0 #06F0FF,1px -1px 0 #06F0FF,-1px 1px 0 #06F0FF,-1px -1px 0 #06F0FF;
                    -moz-text-shadow:0px 1px 0 #06F0FF,0px -1px 0 #06F0FF,1px 0px 0 #06F0FF,-1px 0px 0 #06F0FF,1px 1px 0 #06F0FF,1px -1px 0 #06F0FF,-1px 1px 0 #06F0FF,-1px -1px 0 #06F0FF;
                    color: #151515;

/* Inside auto layout */
}
p{
            font-family: 'Atyp Display';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 23px;
    text-align: left;
    letter-spacing: 0.17em;
    text-transform: uppercase;

    color: #FFFFFF;

}

}
.BicasGif2_text_button_US{


    font-family: 'Atyp Display';
font-style: normal;
font-weight: 400;
font-size: 8px;
line-height: 20px;
/* identical to box height */

text-align: left;
letter-spacing: 0.17em;
text-transform: uppercase;

color: #B1B1B1;

}
.BicasGif2_text_button_all{
margin-right: 108px;
position: absolute;
top: 63%;
right: -100px;
margin-left: 60%;
}
}






@media screen and (max-width: 340px) {

    .BicasGif2 {
        width: 100%;
        padding: 0 10px*$TELvw;
        padding-bottom: 70px*$TELvw;
        margin-top: 3   0px*$TELvw;
        margin-bottom: -150px*$TELvw;
        overflow: hidden;
    }
    .BicasGif2_text {
        width: 100%;
        margin-bottom: 100px*$TELvw;
        position: relative;
        @include flex(flex, column, nowrap, flex-start, center, unset);

        span {
            width: 90%;
            font-size: 62px*$TELvw;
            line-height: 74px*$TELvw;
            margin-bottom: 42px*$TELvw*0.3;
            text-align: left;
            

            &::after {
                display: none;
            }
        }
        p {
            position: absolute;
            width: 143px*$TELvw;
            font-size: 12px*$TELvw;
            line-height: 14px*$TELvw;
            text-align: start;
            align-self: flex-end;
            
            left: -110px;
            top: 150px;
        }
    }
    .BicasGif2_img_wrapper {
        width: 800px*$TELvw*0.6;
        height: 1129px*$TELvw*0.6;
        margin-top: -450px;
        position: relative;
        z-index: -1;

        @include flex(flex, row, nowrap, center, center, unset);

        .BicasGif2_img {
            width: 70%;
            margin-top: 200px;
        }

        img {
            width: 210%;
            height: 210%;
            z-index: 100;
            object-fit: contain;
        }

        .BicasGif2_important {
            display: none;
        }
    }
}