@import "../../../globalStyles";



.NoMater {
    height: 100%;
    width: 100%;
    padding: 0 133px*$vw;

    @include flex(flex, column, nowrap, flex-start, center, unset);

    h3 {
        font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 400;
        font-size: 57px*$vw;
        line-height: 68px*$vw;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
    }
    h2 {
        font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 700;
        font-size: 105px*$vw;
        line-height: 105px*$vw;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
    }
}
.NoMater_items {
    width: 100%;
    margin-top: -17px*$vw;

    .inner {
        width: fit-content;
        height: fit-content;
        
        @include flex(flex, row, wrap, flex-start, flex-start, unset);

        gap: 15px*$vw;
    }
}
.NoMater_item {
    width: 281px*$vw;
    height: 400px*$vw;
    border-radius: 50px*$vw;
    border: double 1px*$vw transparent;
    border-radius: 21px*$vw;
    margin-top: -40px*$vw;

    position: relative;
    z-index: 1;

    @include flex(flex, column, nowrap, flex-start, center, unset);

    .img {
        width: 90%;
        height: 300px*$vw;
        margin-top: 20px*$vw;

        @include flex(flex, row, nowrap, center, center, unset);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .text {
        width: 80%;
        font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 400;
        font-size: 15px*$vw;
        line-height: 24px*$vw;
        text-align: center;
        letter-spacing: 0.015em;
        text-transform: capitalize;
        color: #FFFFFF;
        margin-bottom: 25px*$vw;
    }

    &:nth-child(1) {
        .img {
            img {
                width: 368px;
                height: 207px;
            }
        }
    }
    &:nth-child(2) {
        .img {
            img {
                margin-top: 20px*$vw;
                margin-left: 40px*$vw;
                width: 144px;
                height: 162px;
            }
        }
    }
    &:nth-child(3) {
        .img {
            img {
                margin-top: 40px*$vw;
                width: 290px;
                height: 163px;
            }
        }
    }
    &:nth-child(4) {
        .img {
            img {
                margin-top: 20px*$vw;
                width: 361px;
                height: 254px;
            }
        }
    }
}
.NoMater_item_bg {
    width: 281px*$vw*1.09;
    height: 400px*$vw*1.09;
    position: absolute;
    top: 0px*$vw;
    z-index: -1;

    /* clip-path: url(#svgTextPath);
    filter: blur(10px); */
    

    @include flex(flex, row, nowrap, center, center, unset);

    svg {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
    }
    &::after {
        content: '';
        width: 281px*$vw;
        height: 350px*$vw;
        position: absolute;
        top: 40px*$vw;
        backdrop-filter: blur(15px*$vw);
        border-radius: 50px*$vw;
    }
}











@media screen and (max-width: 500px) {

    .NoMater {
        width: 100%;
        padding: 0 10px*$TELvw;

        h3 {
            font-size: 57px*$TELvw*0.30;
            line-height: 68px*$TELvw*0.30;
        }
        h2 {
            font-size: 105px*$TELvw*0.30;
            line-height: 50px*$TELvw*0.30;
        }
    }
    .NoMater_items {
        width: 100%;
        height: auto;
        margin-top: 15px*$TELvw*0.5;
        /* overflow-x: auto; */

        .inner {
            width: fit-content;
            height: fit-content;

            @include flex(flex, row, wrap, center, flex-start, unset);

            gap: 11px*$TELvw;
        }
    }

    .NoMater_item {
        width: 154px*$TELvw;
        height: 210px*$TELvw;
        border-radius: 50px*$TELvw*0.7;
        border: double 1px*$TELvw*0.7 transparent;
        border-radius: 21px*$TELvw*0.7;
        margin-top: -40px*$vw;
        position: relative;
        z-index: 1;

        .img {
            width: 90%;
            height: 100px*$TELvw;
            margin: 20px*$TELvw 0;

            img {
                width: 100%;
                height: 90%;
            }
        }

        .text {
            font-size: 15px*$TELvw*0.7;
            line-height: 24px*$TELvw*0.7;
            margin-bottom: 25px*$TELvw*0.7;
        }
        &:nth-child(1) {
            .img {
                img {
                    width: 150px;
                    height: 100%;
                }
            }
        }
        &:nth-child(2) {
            .img {
                img {
                    width: 130px;
                    height: 100%;
                    margin-top: 50px;
                    margin-bottom: 25px*$TELvw;
                    margin-left: 15px*$TELvw;
                }
            }
        }
        &:nth-child(3) {
            .img {
                img {
                    height: 100%;
                    width: 150px;
                    margin-top: 0;
                }
            }
        }
        &:nth-child(4) {
            .img {
                img {
                    width: 150px;
                    height: 150%;
                }
            }
        }

    }
    .NoMater_item_bg {
        width: 281px*$TELvw*0.55*1.09;
        height: 400px*$TELvw*0.55*1.09;
        position: absolute;
        top: -30px*$TELvw*0.55;
        z-index: -1;

        @include flex(flex, row, nowrap, center, center, unset);

        svg {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 2;
        }
        &::after {
            content: '';
            width: 281px*$TELvw*0.55;
            height: 350px*$TELvw*0.55;
            position: absolute;
            top: 40px*$TELvw*0.55;
            backdrop-filter: blur(15px*$TELvw*0.55);
            border-radius: 50px*$TELvw*0.55;
        }
    }
}