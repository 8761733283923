@import "../../globalStyles";



.Header {
    width: 100%;
    padding: 42px*$vw 133px*$vw 0 133px*$vw;
    position: absolute;
    top: 0;
    z-index: 10;

    @include flex(flex, row, nowrap, space-between, center, unset);
}
.header_logo {
    font-family: 'Neue Machina';
    font-style: normal;
    font-weight: 400;
    font-size: 21px*$vw;
    line-height: 20px*$vw;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;

    .mobLogo {
        display: none;
    }
}

.header_menu {
    width: fit-content;

    @include flex(flex, row, nowrap, flex-start, center, unset);

    gap: 71px*$vw;

    .nav {

        ul {
            @include flex(flex, row, nowrap, flex-start, center, unset);

            gap: 50px*$vw;

            a {
                font-family: 'Neue Machina';
                font-style: normal;
                font-weight: 400;
                font-size: 17px*$vw;
                line-height: 16px*$vw;
                text-align: center;
                text-transform: uppercase;
                color: #FFFFFF;
            }
        }
    }
}
/* .header_menu_btn {
    border: none;
    outline: none;
    background-color: transparent;
    font-family: 'Neue Machina';
    font-style: normal;
    font-weight: 400;
    font-size: 21px*$vw;
    line-height: 20px*$vw;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
} */
.header_burger {
    display: none;
}




@media screen and (max-width: 500px) {

    .Header {
        width: 100%;
        padding: 30px*$TELvw*0.5 10px*$TELvw 0 10px*$TELvw;
    }
    .header_logo {
        font-size: 21px*$TELvw*0.5;
        line-height: 20px*$TELvw*0.5;

        span {
            display: none;
        }

        .mobLogo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px*$TELvw;
            height: 40px*$TELvw;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .header_menu {
        gap: 20px*$TELvw;

        .nav {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.8);
            padding-top: 50px*$TELvw;
            visibility: hidden;
            opacity: 0;
            transition: 0.3s;

            ul {
                @include flex(flex, column, nowrap, flex-start, center, unset);

                gap: 30px*$TELvw;

                a {
                    font-size: 17px*$TELvw;
                    line-height: 16px*$TELvw;
                }
            }
        }
        .openNav {
            visibility: visible;
            opacity: 1;
        }
    }
    /* .header_menu_btn {
        font-size: 21px*$TELvw*0.6;
        line-height: 20px*$TELvw*0.6;
    } */
    .header_burger {
        @include flex(flex, column, nowrap, space-between, center, unset);
        width: 30px*$TELvw;
        height: 20px*$TELvw;
        position: relative;
        z-index: 11;

        div {
            width: 100%;
            height: 3px*$TELvw;
            background-color: #fff;
            border-radius: 1.5px*$TELvw;
            transition: 0.3s;
        }
    }
    .burger_close {
        position: relative;
        z-index: 11;

        div {
            background-color: #fff;
        }

        div:nth-child(2) {
            display: none;
        }
        div:first-child,
        div:last-child {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-50%, -50%);
        }
        div:first-child {
            transform: rotate(45deg);
        }
        div:last-child {
            transform: rotate(-45deg);
        }
    }
}